import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import Tilt from 'react-parallax-tilt';

import { Button, CTAButton } from '../styled/Buttons';
import Container from '../styled/Container';
import device from '../../device';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';
import ProjectImg from '../Image/ProjectImg';
import SectionTitle from '../styled/SectionTitle';

const projects = [
  {
    // Title
    title: 'Rumer',
    // Image Location
    filename: 'rumer.png',
    // Paragraph 1
    paragraph1:
      "A simple AirBnB-inspired platform where users can reserve specific dates on other users' listings.",
    // Paragraph 2
    paragraph2:
      'This project was built with Node and Express on the back end, React on the front end, and MongoDB for the database.',
    // URL
    liveURL: 'https://rumer.herokuapp.com/',
    // Source URL
    sourceURL: 'https://github.com/hedwardd/rumer',
  },
  {
    // Title
    title: 'D3 Choropleth Map',
    // Image Location
    filename: 'choropleth.png',
    // Paragraph 1
    paragraph1:
      'A data visualization that fetches and then combines education and geographic data from separate API endpoints.',
    // Paragraph 2
    paragraph2: 'This project was built using plain JavaScript and the D3 and TopoJSON libraries.',
    // URL
    liveURL: 'https://codepen.io/hedwardd/full/BazOYmy',
    // Source URL
    sourceURL: 'https://codepen.io/hedwardd/pen/BazOYmy',
  },
  // {
  //   // Title
  //   title: 'To-Do Manager',
  //   // Image Location
  //   filename: 'todo.png',
  //   // Paragraph 1
  //   paragraph1:
  //     'A platform that allows users to create personal to-do lists with custom URLs and themes.',
  //   // Paragraph 2
  //   paragraph2:
  //     'This project was built with Node and Express on the back end, React and Redux on the front end, and PostgreSQL for the database.',
  //   // URL
  //   liveURL: 'https://todo-hd.herokuapp.com/',
  //   // Source URL
  //   sourceURL: 'https://github.com/hedwardd/todo',
  // },
  {
    // Title
    title: 'Markdown Notes',
    // Image Location
    filename: 'markdown.png',
    // Paragraph 1
    paragraph1:
      'A static React app allowing you to create notes using markdown which are saved to local storage.',
    // Paragraph 2
    paragraph2:
      'This project was built using React, React Hooks, and pure CSS.',
    // URL
    liveURL: 'https://markdown-notes-hedwardd.vercel.app/',
    // Source URL
    sourceURL: 'https://github.com/hedwardd/markdown-notes',
  },
  {
    // Title
    title: 'Desktop Pomodoro',
    // Image Location
    filename: 'pomodoro2.png',
    // Paragraph 1
    paragraph1:
      'A Pomodoro app for MacOS menu bar with break mode, custom intervals, and halfway and overtime notifications.',
    // Paragraph 2
    paragraph2:
      'This project was built using Python and Rumps.',
    // URL
    downloadURL: 'https://drive.google.com/file/d/1CvAAU2E1wDvFGcyEqfsEk4RERq9SQ4aY/view?usp=sharing',
    // Source URL
    sourceURL: 'https://github.com/hedwardd/pomodoro',
  },
  // {
  //   // Title
  //   title: 'Pokédex for PokéApi',
  //   // Image Location
  //   filename: 'pomodoro.png',
  //   // Paragraph 1
  //   paragraph1:
  //     '',
  //   // Paragraph 2
  //   paragraph2:
  //     '',
  //   // URL
    // liveURL: '',
  //   // Source URL
  //   sourceURL: 'https://github.com/hedwardd/pokemon',
  // },
];

const ProjectsSection = styled.section`
  width: 100%;
  padding: ${layoutSize[7]} ${layoutSize[2]};
  @media ${device.tablet} {
    padding: ${layoutSize[7]} 0;
  }
  @media ${device.laptop} {
    padding: 0 0 ${layoutSize[7]} 0;
  }
`;

const ProjectsContainer = styled(Container)`
  padding: 0;
`;

const ProjectsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${layoutSize[7]};
  @media ${device.tablet} {
    margin-bottom: ${layoutSize[8]};
  }
  @media ${device.laptop} {
    flex-direction: row;
    margin-bottom: ${layoutSize[9]};
  }
`;

const ProjectInfoContainer = styled.div`
  width: 100%;
  margin-bottom: ${layoutSize[5]};
  padding: 0 ${layoutSize[4]};
  @media ${device.tablet} {
    margin-bottom: ${layoutSize[7]};
  }
  @media ${device.laptop} {
    width: 33.33%;
  }
`;

const ProjectTitle = styled.h3`
  font-size: ${fontSize[6]};
  margin-bottom: ${layoutSize[4]};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ProjectCopyContainer = styled.div`
  margin-bottom: ${layoutSize[4]};
`;

const ProjectCopy = styled.p`
  margin-bottom: ${layoutSize[2]};
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: ${layoutSize[6]};
  padding: 0 ${layoutSize[4]};
  @media ${device.laptop} {
    margin-bottom: 0;
  }
  @media ${device.laptop} {
    width: 66.66%;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  border-radius: 2.5px;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
`;

const Project = ({ title, filename, paragraph1, paragraph2, liveURL, sourceURL, downloadURL }) => (
  <ProjectContainer>
    <ProjectInfoContainer>
      <Fade direction="left" triggerOnce duration={1000} delay={500} fraction={0.75}>
        <>
          <ProjectTitle>{title}</ProjectTitle>
          <ProjectCopyContainer>
            <ProjectCopy>{paragraph1}</ProjectCopy>
            <ProjectCopy>{paragraph2}</ProjectCopy>
          </ProjectCopyContainer>
          <ButtonWrapper>
            {liveURL && (
            <CTAButton href={liveURL} target="_blank">
              See Live
            </CTAButton>
            )}
            {downloadURL && (
            <CTAButton href={downloadURL} target="_blank">
              Download
            </CTAButton>
            )}
            <Button href={sourceURL} target="_blank">Source Code</Button>
          </ButtonWrapper>
        </>
      </Fade>
    </ProjectInfoContainer>
    <ImageContainer>
      <Fade direction="bottom" triggerOnce duration={1000} delay={600}>
        <Tilt glareEnable tiltReverse perspective={3000}>
          <ImageWrapper>
            <ProjectImg filename={filename} />
          </ImageWrapper>
        </Tilt>
      </Fade>
    </ImageContainer>
  </ProjectContainer>
);

Project.propTypes = {
  title: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  paragraph1: PropTypes.string.isRequired,
  paragraph2: PropTypes.string.isRequired,
  sourceURL: PropTypes.string.isRequired,
  liveURL: PropTypes.string,
  downloadURL: PropTypes.string,
};

const Projects = () => (
  <ProjectsSection>
    <ProjectsContainer>
      <Fade delay={300} triggerOnce>
        <SectionTitle>PROJECTS</SectionTitle>
      </Fade>
      <ProjectsWrapper>
        {projects.map((project) => (
          <React.Fragment key={project.title}>
            <Project
              title={project.title}
              filename={project.filename}
              paragraph1={project.paragraph1}
              paragraph2={project.paragraph2}
              liveURL={project.liveURL}
              sourceURL={project.sourceURL}
              downloadURL={project.downloadURL}
            />
          </React.Fragment>
        ))}
      </ProjectsWrapper>
    </ProjectsContainer>
  </ProjectsSection>
);

export default Projects;
