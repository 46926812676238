import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import PropTypes from 'prop-types';

import colors from '../../colors';
import { ContactCTAButton } from '../styled/Buttons';
import Container from '../styled/Container';
import device from '../../device';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';
import SectionTitle from '../styled/SectionTitle';

const ContactSection = styled.section`
  width: 100%;
  background-image: ${colors.gradient};
  padding: ${layoutSize[9]} 0;
  clip-path: polygon(0 17%, 100% 0, 100% 101%, 0 101%);
  @media ${device.laptop} {
    padding: ${layoutSize[10]} 0 ${layoutSize[9]};
    clip-path: polygon(0 33%, 100% 0, 100% 101%, 0 101%);
  }
  @media ${device.laptopL} {
    padding: ${layoutSize[10]} 0 ${layoutSize[9]};
  }
`;

const ContactSectionTitle = styled(SectionTitle)`
  color: white;
`;

const ContactWrapper = styled.div`
  padding: 0 ${layoutSize[4]};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactText = styled.p`
  margin-bottom: ${layoutSize[5]};
  color: white;
  font-size: ${fontSize[5]};
  text-align: center;
  @media ${device.tablet} {
    font-size: ${fontSize[6]};
  }
`;

const Contact = ({ onContactButtonClick, contactButtonRef }) => {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onContactButtonClick();
      e.preventDefault();
    }
  };

  return (
    <ContactSection>
      <Container>
        <Fade delay={300} triggerOnce>
          <ContactSectionTitle>CONTACT</ContactSectionTitle>
        </Fade>
        <ContactWrapper>
          <ContactText>Would you like to work with me? Awesome!</ContactText>
          <ContactCTAButton
            onClick={onContactButtonClick}
            ref={contactButtonRef}
            tabIndex="0"
            onKeyDown={handleKeyDown}
            role="button"
            aria-label="Contact Me"
          >
            Let&apos;s Talk
          </ContactCTAButton>
        </ContactWrapper>
      </Container>
    </ContactSection>
  )
};

Contact.propTypes = {
  onContactButtonClick: PropTypes.func.isRequired,
};

export default Contact;
