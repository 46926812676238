import * as React from 'react';
import { Helmet } from "react-helmet"

import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import Layout from '../components/Layout';
import Projects from '../components/Projects/Projects';
import ContactForm from '../components/Contact/ContactForm';

const IndexPage = () => {
  const [showContactForm, setShowContactForm] = React.useState(false);

  const contactButtonRef = React.useRef();

  const handleContactButtonClick = () => setShowContactForm(true);
  const handleCloseContactForm = () => {
    setShowContactForm(false);
    contactButtonRef?.current?.focus();
  };
  
  return (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Heath Daniel - Full-Stack Web Developer</title>
      <html lang="en" />
      <meta name="Heath Daniel" content="Welcome to my personal portfolio website." />
    </Helmet>
    <Hero />
    <About />
    <Projects />
    <Contact
      onContactButtonClick={handleContactButtonClick} contactButtonRef={contactButtonRef}
     />
    <Footer />
    {showContactForm && <ContactForm hideContactForm={handleCloseContactForm} />}
  </Layout>
)};

export default IndexPage;
