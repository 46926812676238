import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { IconContext } from 'react-icons';
import { FaChevronUp, FaGithub, FaLinkedinIn, FaCodepen, FaTwitter } from 'react-icons/fa';

import layoutSize from '../../layoutSize';

const StyledFooter = styled.footer`
  width: 100%;
  padding: ${layoutSize[7]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: white;
`;

const BackToTop = styled.a`
  margin: ${layoutSize[3]} 0 ${layoutSize[4]} 0;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;

  :hover {
    transform: translateY(-2px);
  }
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialLink = styled.a`
  width: ${layoutSize[7]};
  height: ${layoutSize[7]};
  margin: ${layoutSize[4]};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;

  :hover {
    transform: translateY(-2px);
    color: unset;
  }
`;

const HorizontalRule = styled.hr`
  width: 50%;
  margin: ${layoutSize[3]} auto;
  border: 0;
  border-top: 2px solid hsla(0, 0%, 100%, 0.1);
`;

const BackToTopIconSize = layoutSize[5];
const SocialIconSize = layoutSize[6];

const Footer = () => (
  <StyledFooter>
    <IconContext.Provider value={{ size: BackToTopIconSize }}>
      <BackToTop as={Link} to="hero" smooth duration={1000} aria-label="Back to Top">
        <FaChevronUp />
      </BackToTop>
    </IconContext.Provider>
    <SocialLinksWrapper>
      <IconContext.Provider value={{ size: SocialIconSize }}>
        <SocialLink href="https://www.linkedin.com/in/heath-daniel/" target="_blank" aria-label="LinkedIn">
          <FaLinkedinIn />
        </SocialLink>
        <SocialLink href="https://github.com/hedwardd" target="_blank" aria-label="GitHub">
          <FaGithub />
        </SocialLink>
        <SocialLink href="https://twitter.com/heathedan" target="_blank" aria-label="Twitter">
          <FaTwitter />
        </SocialLink>
        <SocialLink href="https://codepen.io/hedwardd" target="_blank" aria-label="CodePen">
          <FaCodepen />
        </SocialLink>
      </IconContext.Provider>
    </SocialLinksWrapper>
    <HorizontalRule />
  </StyledFooter>
);

export default Footer;
