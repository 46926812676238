import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-scroll';

import colors from '../../colors';
import device from '../../device';
import { HeroCTAButton } from '../styled/Buttons';
import Container from '../styled/Container';
import fontSize from '../../fontSize';
import layoutSize from '../../layoutSize';

const HeroSection = styled.section`
  min-height: 100vh;
  height: 100vh;
  padding: 0 ${layoutSize[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media ${device.tablet} {
    padding: 0 ${layoutSize[8]};
  }
`;

const HeroTitle = styled.h1`
  margin-bottom: ${layoutSize[6]};
  font-size: ${fontSize[7]};
  font-weight: 700;
  color: rgb(39, 35, 65);
  text-align: center;
  @media ${device.mobileM} {
    font-size: ${fontSize[8]};
  }
  @media ${device.tablet} {
    line-height: 1.2;
  }
  @media ${device.laptop} {
    font-size: ${fontSize[9]};
    line-height: 1;
    text-align: left;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize[10]};
    line-height: 1.2;
  }
`;

const HeroTitleSpan = styled.span`
  background-image: ${colors.gradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
`;

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    justify-content: start;
  }
`;

const Hero = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (window.innerWidth > 769) {
      setIsDesktop(true);
    }
  }, []);

  const fadeDirection = isDesktop ? 'left' : 'up';

  return (
    <HeroSection id="hero">
      <Container>
        <Fade direction={fadeDirection} triggerOnce duration={1000} delay={500}>
          <HeroTitle>
            Hi, my name is <HeroTitleSpan>Heath Daniel</HeroTitleSpan>
            <br /> I&apos;m a Full-Stack Web Developer.
          </HeroTitle>
        </Fade>
        <Fade direction={fadeDirection} triggerOnce duration={1000} delay={1000}>
          <CTAWrapper>
            <HeroCTAButton>
              <Link to="about" smooth duration={1000}>
                Know More
              </Link>
            </HeroCTAButton>
          </CTAWrapper>
        </Fade>
      </Container>
    </HeroSection>
  );
};

export default Hero;
