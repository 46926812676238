import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';
import PropTypes from 'prop-types';

import layoutSize from '../../layoutSize';
import device from '../../device';
import { CTAButton, Button } from '../styled/Buttons';
import fontSize from '../../fontSize';

const Blur = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const StyledContactForm = styled.form`
  --card-width: ${layoutSize[14]};
  --card-height: ${layoutSize[14]};
  top: 25%;
  position: fixed;
  width: 90%;
  left: 5%;
  display: flex;
  flex-direction: column;
  padding: ${layoutSize[4]};
  backdrop-filter: blur(16px) saturate(180%);
  background: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  z-index: 2;

  @media ${device.laptop} {
    top: 25%;
    left: calc(50vw - var(--card-width) / 2);
    position: fixed;
    width: var(--card-width);
  }
`;

const FormHeader = styled.h2`
  font-size: ${fontSize[4]};
  margin-bottom: ${layoutSize[4]};
`;

const StyledLabel = styled.label`
  margin-top: ${layoutSize[4]};
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  margin-top: ${layoutSize[1]};
`;

const StyledValidationError = styled(ValidationError)`
  font-size: ${fontSize[2]};
`;

const StyledButtonContainer = styled.div`
  margin-top: ${layoutSize[4]};
  display: flex;
  justify-content: space-evenly;
  
  @media ${device.laptop} {
    justify-content: flex-end;

    >* {
      margin-right: ${layoutSize[4]};
  }
`;

const ContactForm = ({ hideContactForm }) => {
  const [state, handleSubmit] = useForm("mjvlbwyb");
  
  useEffect(() => {
    if (state.succeeded) {
      hideContactForm();
    }
  }, [state.succeeded]);

  useEffect(() => {
    window.addEventListener('keydown', handleTab);
    return () => {
      window.removeEventListener('keydown', handleTab);
    };
  });

  const wrapperRef = useRef();
  const firstFocusableRef = useRef();
  const lastFocusableRef = useRef();

  const handleTab = (e) => {
    if (e.key === 'Tab') {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableRef.current) {
          lastFocusableRef.current.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableRef.current) {
          firstFocusableRef.current.focus();
          e.preventDefault();
        }
      }
    }
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      hideContactForm();
    }
  };

  const handleEscKey = (e) => {
    if (e.keyCode === 27) {
      hideContactForm();
    }
  };

  return (
    <Blur onClick={handleClickOutside} onKeyUp={handleEscKey}>
      <StyledContactForm onSubmit={handleSubmit} ref={wrapperRef} >
        <FormHeader>Contact</FormHeader>
        <StyledLabel htmlFor="name">
          Full Name
        <StyledInput
          id="name"
          type="text" 
          name="name"
          required
          autoFocus
          ref={firstFocusableRef}
        />
        <StyledValidationError 
          prefix="Full name" 
          field="name"
          errors={state.errors}
        />
        </StyledLabel>
        <StyledLabel htmlFor="email">
          Email Address
        <StyledInput
          id="email"
          type="email" 
          name="email"
          required
        />
        <StyledValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        </StyledLabel>
        <StyledLabel htmlFor="message">
          Message
        <StyledInput
          as={'textarea'}
          id="message"
          name="message"
          required
        />
        <StyledValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
        </StyledLabel>
        <StyledButtonContainer>
          <Button as={'button'} onClick={hideContactForm} disabled={state.submitting}>
            Cancel
          </Button>
          <CTAButton as={'button'} type="submit" disabled={state.submitting} ref={lastFocusableRef}>
            Send
          </CTAButton>
        </StyledButtonContainer>
      </StyledContactForm>
    </Blur>
  );
};

ContactForm.propTypes = {
  hideContactForm: PropTypes.func.isRequired,
};

export default ContactForm;